<template>
  <div
    class="service-reservation-page relative-sec"
    v-if="!loading && serviceData"
  >
    <div class="container position-relative">
      <div
        :class="['back-btn', dir_ar == 'dir-rtl' ? 'me-auto' : 'ms-auto']"
        @click="goBack"
      >
        <i class="fa fa-arrow-left" v-if="dir_ar == 'dir-rtl'"></i>
        <i class="fa fa-arrow-right" v-if="dir_ar == 'dir-ltr'"></i>
      </div>
      <form
        :class="['form-container', dir_ar]"
        @submit.prevent="bookTheService"
      >
        <div class="row">
          <div class="col-md-8 col-12">
            <h3 class="header-main d-flex align-items-center">
              {{ serviceData.titles?serviceData.titles[lang]:serviceData.title  }}
              <span class="header-divider d-inline-block mx-1 mt-1"></span>
            </h3>

            <ServiceData :hint="serviceData.data" v-if="serviceData.data" />
            <!-- 
            <div class="input">
              <Datepicker
                v-model="formData.date"
                format="dd-MM-yyyy"
                :language="dateLanguage"
                :thePlaceholder="$t('reservationDate')"
              />
            </div>
            <h6 class="fw-bold mt-5 mb-0">{{ $t("available dates") }}</h6>
            <div class="d-flex flex-wrap appointment input mt-0 w-100">
              <div class="radio-container" v-for="i in 5" :key="i">
                <input
                  type="radio"
                  name="appointment"
                  :value="i"
                  required
                  :id="'appointment' + i"
                  v-model="formData.appointment"
                  @change="checked(i, $event)"
                  :class="selectedClass == i ? 'checked' : ''"
                />
                <label :for="'appointment' + i">{{ "0" + i + ":00 pm" }}</label>
              </div>
            </div> -->
          </div>
          <div v-if="serviceData.image_url" class="col-md-4 d-none d-md-block p-0">
            <img
              :src="serviceData.image_url"
              width="100%"
              alt="Service Image"
            />
          </div>
        </div>
        <!-- <div
          class="d-flex mb-3 w-100 align-items-center justify-content-between"
        >
          <div>
            <button class="serviceNavButton">{{ $t("Next srevice") }}</button>
          </div>
          <div>
            <button class="serviceNavButton" @click="">
              {{ $t("Previous service") }}
            </button>
          </div>
        </div> -->
        <!-- <div class="row">
          <div class="col-md-6 col-12">
            <div class="input w-100">
              <label for="username">
                {{ $t("full Name") }}
              </label>
              <input
                required
                type="text"
                name="username"
                :placeholder="$t('full name')"
                id="username"
                v-model="formData.username"
              />
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="input w-100">
              <label for="phone">
                {{ $t("phone Number") }}
              </label>
              <input
                type="tel"
                name="phone"
                :placeholder="$t('phone Number')"
                id="phone"
                required
                v-model="formData.phone"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="input w-100">
              <label for="address">
                {{ $t("address") }}
              </label>
              <input
                type="text"
                name="address"
                :placeholder="$t('address')"
                id="address"
                v-model="formData.address"
              />
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="input w-100">
              <label for="email">
                {{ $t("email") }}
              </label>
              <input
                type="email"
                name="email"
                :placeholder="$t('email')"
                id="email"
                required
                v-model="formData.email"
              />
            </div>
          </div>
        </div>
        <div class="input add-info w-100">
          <label for="add-info">
            {{ $t("other Data") }}
          </label>
          <textarea
            :placeholder="$t('entertheadditionalinformation')"
            id="add-info"
            v-model="formData.additonalInfo"
          ></textarea>
        </div>
        <button class="d-block w-100 btn-pri btn submit" type="submit">
          {{ $t("reserve now") }}
        </button> -->
      </form>
    </div>
  </div>
  <div class="container py-5 my-5" v-else-if="loading">
    <div class="loader my-5 mx-auto"></div>
  </div>
  <div v-else-if="error" class="error py-5 text-center text-danger">
    <ErrorMsg :message="error" />
  </div>
</template>

<script>
import ServiceData from "@/components/ServiceData.vue";
import { mapGetters, mapActions } from "vuex";
import Datepicker from "vuejs-datepicker";
import { en, de, ar } from "vuejs-datepicker/dist/locale";
import ErrorMsg from "../components/misc/ErrorMsg.vue";
export default {
  components: {
    ServiceData,
    Datepicker,
    ErrorMsg,
  },
  data() {
    return {
      enLang: en,
      deLang: de,
      arLang: ar,
      serviceToReservation: {},
      formData: {
        date: "",
        appointment: "",
        username: "",
        phone: "",
        additonalInfo: "",
        address: "",
        email: "",
      },
      selectedClass: "",
      loading: true,
      serviceData: null,
      error: null,
      cureentIndex: 0
    };
  },
  computed: {
    ...mapGetters(["trendingCards", "service","services"]),
    dateLanguage() {
      if (this.lang == "ar") {
        return this.arLang;
      } else if (this.lang == "en") {
        return this.enLang;
      } else {
        return this.deLang;
      }
    },
  },
  methods: {
    ...mapActions(["loadVService","getServices"]),
    goBack() {
      this.$router.back();
    },

    checked(appointment, e) {
      if (e.srcElement.checked) {
        this.selectedClass = appointment;
      } else {
        this.selectedClass = "";
      }
    },
    bookTheService() {
      console.log(this.formData);
    },
  },
  mounted() {
    this.getServices()
    const serviceId = this.$route.params.id;
    this.loadVService({
      id: serviceId,
    })
      .then((res) => {
        this.serviceData = res.data.results;
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
        this.error =
          err && err.response ? err.response.data.results.message : err;
        this.$notify({
          title: this.$t("Something bad happend"),
          text: this.error,
          position: "bottom right",
        });
      });
  },
  watch: {
    $route(nr) {
      if (nr) {
        const serviceId = this.$route.params.id;
        this.loadVService({
          id: serviceId,
        })
          .then((res) => {
            this.serviceData = res.data.results;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.error =
              err && err.response ? err.response.data.results.message : err;
            this.$notify({
              title: this.$t("Something bad happend"),
              text: this.error,
              position: "bottom right",
            });
          });
      }
    },
  },
};
</script>

<style>
::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}
</style>
